/**
 * Site Info
 */

.footer {
  @include flexbox();
  @include align-items(center);
  height: map-get($layout, footer-height);
  background: $footer-background;
  p {
    color: $footer-text-color;
  }
  a {
    @include link-colors ($footer-text-color, $main-color-1);
  }
  .site-info {
    margin: 1rem auto 0 auto;
    font-size: map-get($base, font-size-xs);
    text-align: center;
    .menu {
      line-height: map-get($base, line-height-xs);
      & > * {
        &:not(:last-child) {
          @include split-line(right, default, $footer-text-color);
          padding-right: .3rem;
          margin-right: .3rem;
        }
      }
    }
  }
}
.footer__author-links {
  @include overflow(auto);
  .author-links {
    padding-top: 1rem;
    text-align: center;
  }
}