.hero {
  padding: map-get($spacers, 5);
  padding-bottom: 0;
  background-position: 50% 50%;
  @include media-breakpoint-down(lg) {
    padding: map-get($spacers, 5) map-get($spacers, 3);
    padding-bottom: 0;
  }
  @include media-breakpoint-down(md) {
    padding: map-get($spacers, 4) map-get($spacers, 3);
    padding-bottom: 0;
  }
  @include flexbox();
  @include align-items(center);
  h1 { font-size: map-get($base, font-size-h1-xl); }
  h2 { font-size: map-get($base, font-size-h2-xl); }
  h3 { font-size: map-get($base, font-size-h3-xl); }
  h4 { font-size: map-get($base, font-size-h4-xl); }
  h5 { font-size: map-get($base, font-size-h5-xl); }
  h6 { font-size: map-get($base, font-size-h6-xl); }
  p  { font-size: map-get($base, font-size-xl); }
  @include media-breakpoint-down(lg) {
    h1 { font-size: map-get($base, font-size-h1-lg); }
    h2 { font-size: map-get($base, font-size-h2-lg); }
    h3 { font-size: map-get($base, font-size-h3-lg); }
    h4 { font-size: map-get($base, font-size-h4-lg); }
    h5 { font-size: map-get($base, font-size-h5-lg); }
    h6 { font-size: map-get($base, font-size-h6-lg); }
    p  { font-size: map-get($base, font-size-lg); }
  }
  @include media-breakpoint-down(md) {
    h1 { font-size: map-get($base, font-size-h1-sm); }
    h2 { font-size: map-get($base, font-size-h2-sm); }
    h3 { font-size: map-get($base, font-size-h3-sm); }
    h4 { font-size: map-get($base, font-size-h4-sm); }
    h5 { font-size: map-get($base, font-size-h5-sm); }
    h6 { font-size: map-get($base, font-size-h6-sm); }
    p  { font-size: map-get($base, font-size); }
  }

  img {
    display: block;
    width: 100%;
    margin: 0 auto;
  }
  background-size: cover;

}

.hero--center {
  text-align: center;
  .menu {
    @extend .menu--center;
  }
}

.hero--light {
  color: $text-color-theme-light;
  h1, h2, h3 {
    color: $text-color-theme-light-d;
  }
  h4, h5 {
    color: $text-color-theme-light;
  }
  h6 {
    color: $text-color-theme-light-l;
  }
}

.hero--dark {
  color: $text-color-theme-dark;
  h1, h2, h3 {
    color: $text-color-theme-dark-d;
  }
  h4, h5 {
    color: $text-color-theme-dark;
  }
  h6 {
    color: $text-color-theme-dark-l;
  }
}

.hero__content {
  width: 100%;
}
.hero__cover {
  max-width: 800px;
}

.heros {
  .hero {
    margin: map-get($spacers, 5);
    @include media-breakpoint-down(lg) {
      margin: map-get($spacers, 3);
    }
    @include media-breakpoint-down(md) {
      margin: map-get($spacers, 2) 0;
    }
  }
}