.layout--article {

  .article__footer {
    margin: map-get($base, vertical-space-lg) 0;
    font-size: map-get($base, font-size-sm);
    color: $text-color-l;
    .article__license {
      img {
        height: map-get($base, font-size) * 1.6;
      }
      a {
        @include link-colors($text-color, $main-color-1);
      }
    }
  }
  .article__section-navigator {
    padding-top: map-get($base, vertical-space-lg) / 2;
    margin: map-get($spacers, 4) 0 map-get($spacers, 3) 0;
    word-wrap: break-word;
    @include split-line(top, 4px);
    & > .previous, & > .next {
      width: 50%;
      & > span {
        font-weight: map-get($base, font-weight-bold);
        color: $text-color-l;
      }
      & > a {
        display: block;
        @include link-colors($text-color, $main-color-1);
      }
    }
    & > .previous {
      float: left;
      padding-right: .5rem;
    }
    & > .next {
      float: right;
      padding-left: .5rem;
      text-align: right;
    }
  }
}