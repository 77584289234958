.article__content {
  p.success {
    @include card(false);
    background-color: rgba($green, .1);
    border: 1px solid $green;
  }

  p.info {
    @include card(false);
    background-color: rgba($blue, .1);
    border: 1px solid $blue;
  }

  p.warning {
    @include card(false);
    background-color: rgba($yellow, .1);
    border: 1px solid $yellow;
  }

  p.error {
    @include card(false);
    background-color: rgba($red, .1);
    border: 1px solid $red;
  }
}