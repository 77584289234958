ul.toc {
  display: block;
  margin: 0;
  list-style-type: none;
  & > li {
    margin: .2rem 0;
    &.active {
      a {
        color: $text-color-3;
        background-color: $main-color-3;
        box-shadow: 0 0 0 2px rgba($main-color-3, .4);
      }
    }
  }

  a {
    display: inline-block;
    padding: .2rem .8rem .2rem .8rem;
    text-decoration: none !important;
    border-radius: map-get($base, border-radius);
    @include clickable($text-color, null, $text-color-3, $main-color-3);
  }

  .toc-h1 {
    @include split-line(bottom);
    padding: .8rem 0 .3rem 0;
    margin-bottom: .6rem;
    a {
      @include link-colors($text-color-d);
    }
  }
  .toc-h1,
  .toc-h2 {
    &, a {
      font-weight: map-get($base, font-weight-bold);
    }
  }
  .toc-h3,
  .toc-h4,
  .toc-h5,
  .toc-h6 {
    &, a {
      font-weight: map-get($base, font-weight);
    }
  }
  .toc-h5,
  .toc-h6 {
    a {
      @include link-colors($text-color-l);
    }
  }
  .toc-h3 {
    margin-left: 1rem;
  }
  .toc-h4 {
    margin-left: 2rem;
  }
  .toc-h5, .toc-h6 {
    margin-left: 3rem;
  }

  .toc-h1 {
    &, a {
      font-size: map-get($base, font-size);
      line-height: map-get($base, line-height);
    }
  }
  .toc-h2 {
    &, a {
      font-size: map-get($base, font-size-sm);
      line-height: map-get($base, line-height-sm);
    }
  }
  .toc-h3,
  .toc-h4,
  .toc-h5,
  .toc-h6 {
    &, a {
      font-size: map-get($base, font-size-xs);
      line-height: map-get($base, line-height-xs);
    }
  }
}

ul.toc--navigator {
  & > li {
    @include active() {
      a {
        background-color: transparent;
      }
    }
    &.active {
      a {
        margin-left: -4px;
        background-color: transparent;
        box-shadow: none;
        @include link-colors($main-color-1);
        @include split-line(left, 4px, $main-color-1);
      }
    }
  }

  .toc-h2,
  .toc-h3,
  .toc-h4 {
    &, a {
      @include link-colors($text-color-l);
    }
  }

  a {
    padding: 0 0 0 .5rem;
    margin: .2rem 0;
    border-radius: 0;
    @include hover() {
      background-color: transparent;
    }
  }
}