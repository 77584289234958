.layout--page {
  height: 100%;

  .page__main {
    height: 100%;
  }
  &.layout--page--sidebar {
    .page__main {
      @include overflow(auto);
    }
  }

  .page__main-inner {
    position: relative;
    height: auto !important;
    height: 100%;
    min-height: 100%;
    color: $text-color;
    background-color: $background-color;

    .col-aside {
      display: none;
      & > aside {
        position: absolute;
        width: map-get($layout, aside-width);
        overflow: hidden;
      }
    }
  }

  .page__content {
    padding-bottom: map-get($layout, footer-height);
    margin: 0 auto;
  }

  .page__comments {
    padding-bottom: map-get($base, vertical-space-lg);
  }

  .page__actions {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    z-index: map-get($z-index, actions);
    display: none;
  }

  .sidebar-button {
    @include clickable($text-color-d, rgba($main-color-3, .75));
  }

  .page__sidebar {
    z-index: map-get($z-index, sidebar);
    display: block;
    float: left;
    width: 80%;
    max-width: map-get($layout, sidebar-width);
    height: 100%;
    background-color: $background-color;
    @include split-line(right);
    @include transition(transform .4s);
    @include overflow(auto);
    .sidebar-toc {
      padding: 1rem 1rem 2rem 2rem;
    }
  }

  .page__mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: map-get($z-index, mask);
    display: none;
    cursor: pointer;
    background-color: $mask-color;
  }

  .page__aside {
    .toc-aside {
      padding: 2.5rem 0 1rem 3rem;
    }
  }

  .page__footer {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .has-aside {
    .page__main-inner{
      .col-aside {
        position: relative;
        display: block;
        width: map-get($layout, aside-width);
        & > aside {
          &.fixed {
            position: fixed;
            -webkit-font-smoothing: subpixel-antialiased;
          }
        }
        @include media-breakpoint-down(lg) {
          display: none;
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .page__sidebar {
      position: fixed;
      @include transform(translate(- map-get($layout, sidebar-width), 0));
    }

    .page__actions {
      display: block;
    }

    &.show-sidebar {
      .page__sidebar {
        @include transform(translate(0));
      }
      .page__mask {
        display: block;
      }
    }
  }
  .page__search-panel {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: map-get($z-index, search);
    display: none;
    color: $text-color;
    background-color: $background-color;
    @include overflow(auto);
  }
  &.show-search-panel {
    .page__main {
      @include overflow(hidden);
    }
    .page__search-panel {
      display: block;
    }
  }

  .hero--light {
    .article__info {
      color: $text-color-theme-light;
    }
  }
  .hero--dark {
    .article__info {
      color: $text-color-theme-dark;
    }
  }
}

.page__main--immersive {
  .page__header {
    position: absolute;
    width: 100%;
  }
  .hero__content {
    padding-top: map-get($layout, header-height);
  }

}