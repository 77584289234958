@mixin media-breakpoint-down($name, $breakpoints: default) {
  @if $breakpoints == default {
    $breakpoints: $responsive;
  }
  @media screen and (max-width: map-get($breakpoints, $name)) {
    @content;
  }
}

@mixin media-breakpoint-up($name, $breakpoints: default) {
  @if $breakpoints == default {
    $breakpoints: $responsive;
  }
  @media screen and (min-width: map-get($breakpoints, $name)) {
    @content;
  }
}