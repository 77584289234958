@mixin animation($value) {
  -webkit-animation: $value;
  animation: $value;
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}