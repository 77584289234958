@mixin make-spacing($type, $direction, $spacer, $negative: false) {

  $css_type: null;
  $css_directions: null;

  @if ($type == "m") {
    $css_type: "margin";
  } @else if ($type == "p") {
    $css_type: "padding";
  }

  @if ($direction == "t") {
    $css_directions: ("top");
  }
  @else if ($direction == "b") {
    $css_directions: ("bottom");
  }
  @else if ($direction == "l") {
    $css_directions: ("left");
  }
  @else if ($direction == "r") {
    $css_directions: ("right");
  }
  @else if ($direction == "x") {
    $css_directions: ("left", "right");
  }
  @else if ($direction == "y") {
    $css_directions: ("top", "bottom");
  }
  @else if ($direction == "") {
    $css_directions: ("");
  }

  @each $direction in $css_directions {
    @if ($spacer == "auto") {
      @if ($direction == "") {
        #{$css_type}: auto;
      } @else {
        #{$css_type}-#{$direction}: auto;
      }
    } @else {
      @if ($direction == "") {
        @if ($negative == true) {
          #{$css_type}: - map-get($spacers, $spacer);
        } @else {
          #{$css_type}: map-get($spacers, $spacer);
        }
      } @else {
        @if ($negative == true) {
          #{$css_type}-#{$direction}: - map-get($spacers, $spacer);
        } @else {
          #{$css_type}-#{$direction}: map-get($spacers, $spacer);
        }
      }
    }
  }
}

@mixin make-spacings() {
  $types: ("m", "p");
  $directions: ("t", "b", "l", "r", "x", "y", "");
  $spacers: (0, 1, 2, 3, 4, 5);

  @each $type in $types {
    @each $direction in $directions {
      @each $spacer in $spacers {
        .#{$type}#{$direction}-#{$spacer} {
          @include make-spacing($type, $direction, $spacer);
        }
      }
    }
  }

  @each $direction in $directions {
    .m#{$direction}-auto {
      @include make-spacing("m", $direction, "auto");
    }
  }
}

@include make-spacings();