.search-bar {
  @include flexbox();
  margin: map-get($base, vertical-space-lg) 0;
}
.search-box {
  position: relative;
  width: 100%;
  max-width: 22rem;
  @include media-breakpoint-down(md) {
    width: 100%;
    max-width: none;
  }
  & > input {
    display: inline-block;
    width: 100%;
    height: $button-height-lg;
    padding: 0 1.8rem;
    margin: 0;
    line-height: 1 !important;
    background-color: transparent;
    border: 2px solid $border-color;
    border-radius: map-get($button, pill-radius);
    -webkit-appearance: none; /* fix iOS don't display box-shadow properly */
    @include transition(box-shadow map-get($animation, duration) map-get($animation, timing-function));
    @include focus {
      box-shadow: 0 0 0 2px rgba($border-color, .4);
    }
  }
  & > .search-box__icon-search {
    color: $text-color-l;
  }
  & > .search-box__icon-clear {
    & > a {
      @include link-colors($text-color);
      cursor: pointer;
    }
  }
  & > .search-box__icon-search, & > .search-box__icon-clear {
    position: absolute;
    width: $button-height-lg;
    height: $button-height-lg;
    line-height: $button-height-lg;
    text-align: center;
    vertical-align: middle;
  }
  &.not-empty > .search-box__icon-clear {
    display: block;
  }
  & > .search-box__icon-clear {
    top: 0;
    right: 0;
    display: none;
  }
  & > .search-box__icon-search {
    top: 0;
    left: 0;
  }
}
.search-result {
  margin: map-get($base, vertical-space-lg) 0;
  font-size: map-get($base, font-size-sm);
  line-height: map-get($base, line-height-sm);
}
.search-result__header {
  margin: map-get($base, vertical-space) * 2 0 map-get($base, vertical-space) 0;
  font-size: map-get($base, font-size-lg);
  font-weight: map-get($base, font-weight-bold);
  color: $text-color-l;
  text-transform: uppercase;
}
.search-result__item {
  list-style-type: none;
  a {
    padding: .4rem 1rem;
    @include clickable($text-color, transparent, $text-color-3, $main-color-3);
  }
  &.active {
    a {
      color: $text-color-3;
      background-color: $main-color-3;
    }
  }
}
.search__header {
  margin-top: 1.5rem;
  font-size: map-get($base, font-size-h1);
  font-weight: map-get($base, font-weight-bold);
  color: $text-color-d;
  @include media-breakpoint-down(md) {
    display: none;
  }
}
.search__cancel {
  margin-left: map-get($spacers, 2);
  font-weight: map-get($base, font-weight-bold);
  white-space: nowrap;
}