.main {
  width: 100%;
  max-width: map-get($layout, content-max-width);
  padding: 0 2rem;
  margin: 0 auto;
  @include media-breakpoint-down(lg) {
    padding: 0 1rem;
  }
}

.has-aside {
  .main {
    max-width: map-get($layout, content-max-width) + map-get($layout, aside-width);
    @include media-breakpoint-down(lg) {
      max-width: map-get($layout, content-max-width);
      padding: 0 1rem;
    }
  }
}

.full-width {
  .main {
    width: 100%;
    max-width: 100%;
  }
}