.author-profile {
  max-width: 25rem;
  margin: map-get($base, vertical-space-lg) 0;
  font-size: map-get($base, font-size-sm);
  background-color: $text-background-color;
  @include media-breakpoint-down(md) {
    text-align: center;
  }
  img {
    width: 5rem;
    margin-top: map-get($base, vertical-space);
    border-radius: 50%;
  }
}
.author-profile__name {
  font-size: map-get($base, font-size-lg);
  font-weight: map-get($base, font-weight-bold);
  a {
    @include link-colors($text-color, $main-color-1);
  }
}
.author-profile__links {
  @include overflow(auto);
}