.layout--archive {
  & > .layout--archive__result {
    margin: map-get($base, vertical-space-lg) 0;
    .item__meta {
      padding: 0 1rem 0 0;
      font-family: map-get($base, font-family-code);
      font-size: map-get($base, font-size-sm);
      white-space: nowrap;
    }
  }
}
