@mixin card($has-shadow: default) {
  @if $has-shadow == default {
    $has-shadow: true;
  }
  padding: .5rem 1rem;
  border-radius: map-get($base, border-radius);
  @if $has-shadow {
    @include box-shadow();
  }
}

.card {
  @include card();
}

.card--no-shadow {
  @include box-shadow(0);
}
