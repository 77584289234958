@mixin clearfix() {
  &::after {
    display: table;
    clear: both;
    content: "";
  }
}

.clearfix {
  @include clearfix();
}

.left {
  float: left;
}

.right {
  float: right;
}